import React from 'react';
import styles from './LookingFor.module.scss';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Mail from '@material-ui/icons/Mail';
import GatsbyLink from 'gatsby-link';

const LookingFor = () => {
  return (
    <Section height='auto'>
      <div className={styles.looking}>
        <Typography variant="caption" align="center" color="primary">
          Looking for a technology partner?
          <br />
          We'd love to lend a hand.
        </Typography>
        <GatsbyLink to="/contact-us">
          <Button
            className={`${styles['looking__text-button']} button`}
            variant="contained"
            color="primary"
            startIcon={<Mail />}
            id={'main_tech-partner-banner'}
          >
            Contact Us
          </Button>
        </GatsbyLink>
      </div>
    </Section>
  );
};

export default LookingFor;
