import React from 'react';
import styles from './Benefits.module.scss';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import HorizontalCard from '../HorizontalCard';
import CARDS_CONTENT from '../../constants/benefits';
import ExpandingCard from '../ExpandingCard';
import GatsbyLink from "gatsby-link";

const Benefits = () => {
  const { s } = useStaticQuery(graphql`
    query {
      s: allFile(filter: { sourceInstanceName: { eq: "benefitsImages" } }) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
    }
  `);
  const sorted = s.nodes.sort((a, b) => a.name - b.name);
  return (
    <Section backgroundColor="#F7F8FB" height="auto">
      <div className={styles.benefits}>
        <Typography align="center" variant="h2" color="secondary">
          Benefits of cloud migration
        </Typography>
        <div className={styles['benefits__block']}>
          {sorted.map((item, index) => (
            <React.Fragment key={item.publicURL}>
              <div className={styles['benefits__cards']} key={index}>
                <GatsbyLink to={"/services/migration-to-google-cloud"}>
                <HorizontalCard
                  height={'100%'}
                  imgWidth={80}
                  hasBoxShadow={true}
                  icon={item.publicURL}
                  title={CARDS_CONTENT[index].title}
                  text={CARDS_CONTENT[index].text}
                />
                </GatsbyLink>
              </div>
              <div className={styles['benefits__mobile-cards']} key={index + 3}>
                <ExpandingCard
                  img={item.publicURL}
                  smallCard
                >
                  {{collapsedView: (
                    <>
                      <Typography
                        variant={'h4'}
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                        }}
                      >
                        {CARDS_CONTENT[index].title}
                      </Typography>
                    </>
                    ),
                    expandedView: (
                    <div className={'expanding-card-container'} style={{textAlign: "center"}}>
                      <hr
                        style={{
                          background: '#DFE4ED'
                        }}
                      />
                      {CARDS_CONTENT[index].text}
                    </div>)}}
                </ExpandingCard>
              </div>
            </React.Fragment>
          ))}
        </div>
        <Button
          variant="contained"
          href="/services/migration-to-google-cloud"
          endIcon={<ArrowForward />}
          color="secondary"
        >
          View more
        </Button>
      </div>
    </Section>
  );
};

export default Benefits;
