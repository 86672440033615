import React from 'react';
import Hexagon from 'react-hexagon';
import cn from 'classnames';
import styles from './ServiceItem.module.scss';

const ServiceItem = ({
  name,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  url,
  src,
  additionalStyles,
  flatTop,
  isHovered,
}) => {
  let isSafari;

  if (typeof window !== 'undefined') {
    isSafari = navigator.vendor.match(/apple/i);
  }

  return (
    <Hexagon
      key={name}
      className={cn({
        [styles.hexagon]: true,
        [styles.hexagon__hovered]: isHovered && !isSafari,
        [styles.hexagon__hovered__safari]: isHovered && isSafari,
      })}
      hexProps={{
        className: styles['hexagon__inner'],
      }}
      flatTop={flatTop}
      style={{
        cursor: 'pointer',
        pointerEvents: 'auto',
        stroke: 'none',
        fill: '#fff',
        borderRadius: '4px',
        overflow: 'hidden',
        // filter: 'none',
        ...additionalStyles,
      }}
    >
      <foreignObject
        x={flatTop ? '9%' : '7.5%'}
        y="20%"
        width={'100%'}
        height={'100%'}
        className={styles['hexagon__content']}
      >
        <a
          className={styles['hexagon__hover-zone']}
          onMouseEnter={() => onMouseEnter(name)}
          onMouseLeave={() => onMouseLeave(name)}
          href={url}
        >
          <span style={{ display: 'none' }}>{url}</span>
        </a>
        <img width={flatTop ? '395px' : '370px'} src={src} alt="name" />
      </foreignObject>
    </Hexagon>
  );
};

export default ServiceItem;
