import React from 'react';
import styles from './MobileFirstScreen.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import ServiceItem from '../ServiceItem';
import Img from 'gatsby-image';
import SERVICES_INFO from '../../constants/services-info';

const MobileFirstScreen = () => {
  const { bg, services, google } = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: { sourceInstanceName: { eq: "mobile" }, name: { eq: "bg" } }
      ) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
      services: allFile(
        filter: { sourceInstanceName: { eq: "servicesImages" } }
      ) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
      google: allFile(
        filter: {
          sourceInstanceName: { eq: "socialImages" }
          name: { eq: "google" }
        }
      ) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const leftColumn = [];
  const centerColumn = [];
  const rightColumn = [];

  const sorted = services.nodes.sort((a, b) => a.name - b.name);
  sorted.forEach(service => {
    if ([1, 4, 6].includes(+service.name)) {
      leftColumn.push(service);
    } else if ([2, 7].includes(+service.name)) {
      centerColumn.push(service);
    } else {
      rightColumn.push(service);
    }
  });

  return (
    <div
      className={styles['first-screen']}
      style={{
        background: `url(${bg.nodes[0].publicURL})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Typography className={styles['first-screen__heading']}>
        Reimagine <br /> Cloud and Big Data Innovations
      </Typography>
      <Typography className={styles['first-screen__sub-heading']}>
        Level up your digital transformation with a proficient technology
        partner and unlock new opportunities for your business growth by
        harnessing all-round data engineering, machine learning, and cloud tech.
      </Typography>
      <div className={styles['first-screen__services-block']}>
        <div className={styles['first-screen__services-block__first-column']}>
          {leftColumn.map(node => (
            <ServiceItem
              flatTop={true}
              name={node.name}
              src={node.publicURL}
              url={SERVICES_INFO[node.name].url}
              key={node.name}
            />
          ))}
        </div>
        <div className={styles['first-screen__services-block__second-column']}>
          {centerColumn.map(node => (
            <ServiceItem
              flatTop={true}
              name={node.name}
              src={node.publicURL}
              url={SERVICES_INFO[node.name].url}
              key={node.name}
            />
          ))}
        </div>
        <div className={styles['first-screen__services-block__third-column']}>
          {rightColumn.map(node => (
            <ServiceItem
              flatTop={true}
              name={node.name}
              src={node.publicURL}
              key={node.name}
              url={SERVICES_INFO[node.name].url}
            />
          ))}
        </div>
      </div>

      <div className={styles['first-screen__google']}>
        <div className={styles['first-screen__google__info-wrapper']}>
          <Img
            className={styles['first-screen__google__img']}
            fluid={google.nodes[0].childImageSharp.fluid}
            key={google.nodes[0].childImageSharp.fluid}
          />
          <Typography classes={{ root: styles['first-screen__google__text'] }}>
            Google SI and PS partner
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MobileFirstScreen;
