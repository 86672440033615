import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import Homepage from "../components/Homepage";
import {graphql, useStaticQuery} from "gatsby";

const IndexTemplate = ({ location }) => {
  const { subtitle: siteSubtitle, title } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "main" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);
  return (
    <Layout title={title}
            description={siteSubtitle}
            location={location}
            mainPage
            socialImage={bg.nodes[0].publicURL}>
      <Homepage/>
    </Layout>
  );
};
export default IndexTemplate;
