import React from 'react';
import Slider from '../Slider';
import Services from '../Services';
import ServicesNew from '../ServicesNew';
import styles from './Homepage.module.scss';
import ClientsAndPartners from '../ClientsAndPartners';
import Testimonials from '../Testimonials';
import Delivery from '../Delivery';
import LookingFor from '../LookingFor';
import Benefits from '../Benefits';
import MobileFirstScreen from '../MobileFirstScreen';
import MobileExpandedCardsBlock from '../MobileExpandedCardsBlock';

const Homepage = () => {
  return (
    <div className={styles.homepage}>
      <Slider />
      <MobileFirstScreen />
      <MobileExpandedCardsBlock />
      <ServicesNew />
      <ClientsAndPartners />
      <Testimonials />
      <Delivery />
      <LookingFor />
      <Benefits />
    </div>
  );
};

export default Homepage;
