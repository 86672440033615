import React from 'react';
import styles from './Slider.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { SLIDERS } from '../../constants';

import './slider.scss';
import Section from '../Section';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Carousel from '../Carousel/Carousel';

const slideMap = ['kaa', 'fourth', 'first', 'second', 'third', 'fifth'];
const Slider = () => {
  const { sliders, google } = useStaticQuery(graphql`
    query sliderLogosAndSliders {
      sliders: allFile(filter: { sourceInstanceName: { eq: "sliders" } }) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      google: allFile(
        filter: {
          sourceInstanceName: { eq: "socialImages" }
          name: { eq: "google" }
        }
      ) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return (
    <Section height={'auto'}>
      <Carousel
        classes={{
          indicators: 'carousel-indicators',
          indicator: 'carousel-indicators-indicator',
          active: 'carousel-indicators-indicator-active',
        }}
        autoPlay
        interval={7000}
        timeout={150}
        navButtonsAlwaysVisible
        className={styles['slider__carousel']}
        buttonIdent={'2vw'}
        startAt={0}
        topIndent={'45%'}
      >
        {sliders.nodes
          .sort((a, b) => slideMap.indexOf(a.name) - slideMap.indexOf(b.name))
          .map(node => (
            <div
              key={node.name}
              {...SLIDERS[node.name].link && {
                className: styles['clickable'],
                onClick: () => window.open(SLIDERS[node.name].link)
              }}
            >
              <Img
                className={styles['slider__carousel_image']}
                fluid={node.childImageSharp.fluid}
                key={node.childImageSharp.fluid}
              />
              <div key={node.name} className={styles['slider__carousel_text']}>
                <Typography
                  variant="h1"
                  className={styles['slider__carousel_text__header']}
                >
                  {SLIDERS[node.name].title.map(line => (
                    <React.Fragment key={line}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
                <Typography
                  variant="h4"
                  className={styles['slider__carousel_text__regular']}
                >
                  {SLIDERS[node.name].text.map((line, index, lines) => (
                    <React.Fragment key={line}>
                      {line}
                      {SLIDERS[node.name].link && index === lines.length - 1 && <Button
                        className={styles['slider__carousel_text__regular__button']}
                        color="primary"
                        variant="contained"
                      >
                        <ArrowForward />
                      </Button>}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </div>
            </div>
          ))}
      </Carousel>

      <div className={styles['slider__bottom-sidebar']}>
        <div className={styles['google']}>
          <div className={styles['google__info-wrapper']}>
            <Img
              className={styles['google__img']}
              fluid={google.nodes[0].childImageSharp.fluid}
              key={google.nodes[0].childImageSharp.fluid}
            />
            <Typography classes={{ root: styles['google__text'] }}>
              Google SI and PS partner
            </Typography>
          </div>
        </div>
      </div>
      {/*<div className={styles['slider__bottom-sidebar']}>*/}
      {/*  {sliderLogos.nodes*/}
      {/*    .sort((a, b) => a.name.localeCompare(b.name))*/}
      {/*    .map(node => (*/}
      {/*      <div*/}
      {/*        key={node.name}*/}
      {/*        className={styles['slider__bottom-sidebar_icon-wrapper']}*/}
      {/*        onClick={() => slide(node.name)}*/}
      {/*      >*/}
      {/*        <img*/}
      {/*          className={*/}
      {/*            styles[*/}
      {/*              'slider__bottom-sidebar_icon-wrapper_icon' +*/}
      {/*                `_${node.name}` +*/}
      {/*                `${activeSlide[node.name] ? '' : '-not-active'}`*/}
      {/*            ]*/}
      {/*          }*/}
      {/*          src={node.publicURL}*/}
      {/*          alt={node.name}*/}
      {/*          key={node.id}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*</div>*/}
    </Section>
  );
};

export default Slider;
