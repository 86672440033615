const CARDS_CONTENT = [
    {
        title: 'Move your infrastructure to the cloud',
        text: 'Leverage the experience of our certified engineers to securely move your applications and data to the cloud at once.'
    },
    {
        title: 'Securely transfer your data to the cloud',
        text: 'Cybervision provides industry-leading services to help enterprises carry out predictable data migrations from on-premises data centers to the cloud.'
    },
    {
        title: 'Application migration',
        text: 'Our dedicated experts perform the transition of business applications to the cloud in an accurate, predictable, and secure way.'
    }]

export default CARDS_CONTENT
