import React from 'react';
import styles from './Delivery.module.scss';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import BulletPointList from "../BulletPointList";
import {DELIVERY} from "../../constants";

const Delivery = () => {
    return (
        <Section backgroundColor="#fff" height={'auto'}>
            <div className={styles.delivery}>
                <div className={styles['heading-container']}>
                    <Typography align="center" variant="h2" color="secondary">
                        Our Delivery Model
                    </Typography>
                </div>
                <BulletPointList cardWidth={'262px'}
                                 cardHeight={'auto'}
                                 cards={DELIVERY}/>
            </div>
        </Section>
    );
};

export default Delivery;
