import React from 'react';
import styles from './MobileExpandedCardsBlock.module.scss';
import ExpandingCard from '../ExpandingCard';
import { EXPANDING_CARDS } from '../../constants/homepage';
import {graphql, useStaticQuery} from "gatsby";
import Typography from "@material-ui/core/Typography";

const MobileExpandedCardsBlock = () => {
  const { plus, minus } = useStaticQuery(graphql`
    query {
      plus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "plus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
      minus: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "minus" }
        }
      ){
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  return (
    <div className={styles.main}>
      {EXPANDING_CARDS.map(card => (
        <ExpandingCard key={card.title}
                       expander={plus.nodes[0].publicURL}
                       collapser={minus.nodes[0].publicURL}
        >
          {{
            collapsedView: (
              <>
                <Typography
                  variant={'h4'}
                >
                  {card.title}
                </Typography>
              </>),
            expandedView: (
              <div className={'expanding-card-container'}>
                <hr
                  style={{
                    background: card.color
                  }}
                />
                {card.text}
              </div>)
          }}
        </ExpandingCard>
      ))}
    </div>
  );
};

export default MobileExpandedCardsBlock;
