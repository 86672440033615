import React from 'react';
import styles from './Testimonials.module.scss';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../Section';

import './testimonials.scss';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Carousel from '../Carousel/Carousel';
import BigHorizontalCard from '../BigHorizontalCard';
import { ABOUT_CLIENTS } from '../../constants/about-clients';

function Testimonials() {
  const { s, clientsIcons } = useStaticQuery(graphql`
    query {
      s: allFile(filter: { sourceInstanceName: { eq: "testimonialsImages" } }) {
        nodes {
          relativePath
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      clientsIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutClients" }
          relativeDirectory: { eq: "clients" }
        }
      ) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 376, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);
  const background = s.nodes.find(node => node.name === 'bg');
  const sorted = clientsIcons.nodes.sort((a, b) => a.name - b.name);

  return (
    <Section height={'auto'}>
      <img
        className={styles['testimonials__background']}
        src={background.publicURL}
        alt={background.name}
      />
      <div className={styles.testimonials}>
        <Typography align="center" variant="h2" color="primary">
          Clients
        </Typography>

        <div
          style={{
            background: '#fff',
            position: 'relative',
            marginBottom: 56,
            margin: '0 auto 56px',
            borderRadius: 16,
            maxWidth: 1250,
          }}
        >
          <Carousel
            classes={{
              root: 'testimonials',
              next: 'next_btn',
              prev: 'prev_btn',
            }}
            autoPlay={false}
            interval={7000}
            timeout={300}
            indicators={false}
            animation="fade"
            navButtonsAlwaysVisible
            className={styles['testimonials__carousel']}
            buttonIdent={-60}
            topIndent={150}
          >
            {sorted.map((node, index) => (
              <BigHorizontalCard
                key={index}
                node={node}
                index={index}
                data={ABOUT_CLIENTS}
                svgImg
                borderRight
                borderRadius
                background={'transparent'}
              />
            ))}
          </Carousel>
        </div>

        <Button
          className={styles['testimonials__button']}
          variant="contained"
          endIcon={<ArrowForward />}
          color="primary"
          href={'/clients-and-testimonials'}
        >
          View more
        </Button>
      </div>
    </Section>
  );
}

export default Testimonials;
