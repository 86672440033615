import React from 'react';
import styles from './ClientsAndPartners.module.scss';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Img from 'gatsby-image';

const ClientsAndPartners = () => {
  const { s } = useStaticQuery(graphql`
    query {
      s: allFile(filter: { sourceInstanceName: { eq: "clientsImages" } }) {
        nodes {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);
  const sorted = s.nodes.sort((a, b) => a.name - b.name);

  return (
    <Section backgroundColor="#fff" height={'auto'}>
      <div className={styles.clients}>
        <Typography variant="h2" color="secondary">
          {/*<span className={styles['clients__logos']}>Clients and</span> partners*/}
          <span className={styles['clients__heading']}>Clients and</span>{' '}
          partners
        </Typography>
        <div className={styles['clients__logos']}>
          {sorted.map(node => (
            <Img
              className={styles['clients__logos__image']}
              key={node.name}
              fluid={node.childImageSharp.fluid}
              alt={node.name}
            />
          ))}
        </div>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          color="secondary"
          className={styles['clients__button']}
          href={'/clients-and-testimonials'}
        >
          View more
        </Button>
      </div>
    </Section>
  );
};

export default ClientsAndPartners;
