export const EXPANDING_CARDS = [
  {
    title: 'Migrate to the cloud with an official Google Cloud partner',
    text:
      'Transfer your infrastructure, enterprise applications, or data to the cloud in a fast, reliable, and cost-effective way',
    color: '#DFE4ED',
  },
  {
    title: 'Enterprise on-prem support for CDAP from expert contributors',
    text:
      'Effortless on-premise and cloud deployment of the end-to-end data ingestion system',
    color: '#DFE4ED',
  },
  {
    title: 'Full-stack Hadoop and Data Analytics solutions',
    text:
      'We can help you grow your business through intelligent data management and analytics, focusing on strong business outcomes as much as technological excellence',
    color: '#DFE4ED',
  },
];
