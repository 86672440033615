import React, { Component } from 'react';
import { Fade, IconButton, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import autoBind from 'auto-bind';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSwipeable } from 'react-swipeable';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

const styles = {
  root: {
    position: 'relative',
  },
  indicators: {
    width: '100%',
    marginTop: '10px',
    textAlign: 'center',
  },
  indicator: {
    fontSize: '15px',
    cursor: 'pointer',
    transition: '200ms',
    color: '#afafaf',
    '&:hover': {
      color: '#1f1f1f',
    },
    '&:active': {
      color: '#1f1f1f',
    },
  },
  active: {
    color: '#494949',
  },
  buttonWrapper: {
    height: '100px',
    backgroundColor: 'transparent',
  },
  fullHeightHoverWrapper: {
    height: 'calc(100% - 20px - 10px) !important',

    '@media (max-width:685px)': {
      height: '20px !important',
    },
  },
  button: {
    margin: '0 10px',
    position: 'absolute',
    backgroundColor: 'transparent',
    top: '30px',
    color: 'white',
    fontSize: '30px',
    transition: '200ms',
    cursor: 'pointer',

    '@media (max-width:685px)': {
      color: '#C7CED9',
    },
  },
  fullHeightHoverButton: {
    // top: 'calc(50% - 20px) !important',
  },
  buttonVisible: {
    opacity: '1',
  },
  buttonHidden: {
    opacity: '0',
  },
  next: {
    right: 0,

    'media (max-width:685px)': {
      right: 16,
    },
  },
  prev: {
    left: 0,

    '@media (max-width:685px)': {
      left: 16,
    },
  },
};

class Carousel extends Component {
  constructor(props) {
    super(props);

    const strictIndexing =
      this.props.strictIndexing !== undefined ? props.strictIndexing : true;
    let startAt = this.props.startAt !== undefined ? props.startAt : 0;
    startAt = Array.isArray(this.props.children)
      ? strictIndexing && startAt > this.props.children.length - 1
        ? this.props.children.length - 1
        : startAt
      : 0;

    this.state = {
      active: startAt,
      autoPlay: this.props.autoPlay !== undefined ? this.props.autoPlay : true,
      interval: this.props.interval !== undefined ? this.props.interval : 4000,
      displayed: startAt,
    };

    this.timer = null;

    autoBind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.autoPlay !== prevState.autoPlay ||
      nextProps.interval !== prevState.interval
    ) {
      return {
        autoPlay: nextProps.autoPlay !== undefined ? nextProps.autoPlay : true,
        interval: nextProps.interval !== undefined ? nextProps.interval : 4000,
      };
    } else return null;
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.autoPlay !== prevState.autoPlay ||
      prevProps.interval !== prevState.interval
    ) {
      this.reset();
    }

    if (this.props.startAt !== prevProps.startAt) {
      this.setState({
        active: this.props.startAt,
        displayed: this.props.startAt,
      });
    }
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  start() {
    if (this.state.autoPlay) {
      this.timer = setInterval(this.next, this.state.interval);
    }
  }

  reset() {
    this.stop();

    if (this.state.autoPlay) {
      this.start();
    }
  }

  pressIndicator(index) {
    const animation =
      this.props.animation !== undefined ? this.props.animation : 'fade';

    const timeout =
      this.props.timeout !== undefined
        ? this.props.timeout
        : animation === 'fade'
        ? 500
        : 200;

    this.setState(
      {
        active: index,
        displayed: this.state.active,
      },
      this.reset
    );

    setTimeout(() => {
      this.setState({
        displayed: index,
      });
    }, timeout);
  }

  next(event) {
    const active = this.state.active;
    const next =
      this.state.active + 1 > this.props.children.length - 1
        ? 0
        : this.state.active + 1;
    const animation =
      this.props.animation !== undefined ? this.props.animation : 'fade';
    const timeout =
      this.props.timeout !== undefined
        ? this.props.timeout
        : animation === 'fade'
        ? 500
        : 200;
    const userNext = this.props.next !== undefined ? this.props.next : () => {};

    this.setState(
      {
        active: next,
        displayed: this.state.active,
      },
      this.reset
    );

    setTimeout(() => {
      this.setState(
        {
          displayed: next,
        },
        () => userNext(next, active)
      );
    }, timeout);

    if (event) event.stopPropagation();
  }

  prev(event) {
    const active = this.state.active;
    const prev =
      this.state.active - 1 < 0
        ? this.props.children.length - 1
        : this.state.active - 1;
    const animation =
      this.props.animation !== undefined ? this.props.animation : 'fade';
    const timeout =
      this.props.timeout !== undefined
        ? this.props.timeout
        : animation === 'fade'
        ? 500
        : 200;
    const userPrev = this.props.prev !== undefined ? this.props.prev : () => {};

    this.setState(
      {
        active: prev,
        displayed: this.state.active,
      },
      this.reset
    );

    setTimeout(() => {
      this.setState(
        {
          displayed: prev,
        },
        userPrev(prev, active)
      );
    }, timeout);

    if (event) event.stopPropagation();
  }

  render() {
    const indicators =
      this.props.indicators !== undefined ? this.props.indicators : true;
    const navButtonsAlwaysVisible =
      this.props.navButtonsAlwaysVisible !== undefined
        ? this.props.navButtonsAlwaysVisible
        : false;
    const animation =
      this.props.animation !== undefined ? this.props.animation : 'fade';
    const timeout =
      this.props.timeout !== undefined
        ? this.props.timeout
        : animation === 'fade'
        ? 500
        : 200;
    const fullHeightHover =
      this.props.fullHeightHover !== undefined
        ? this.props.fullHeightHover
        : true;

    const classes = this.props.classes;
    const buttonCssClassValue = `${classes.button} ${
      navButtonsAlwaysVisible ? classes.buttonVisible : classes.buttonHidden
    } ${fullHeightHover ? classes.fullHeightHoverButton : ''}`;
    const buttonWrapperCssClassValue = `${classes.buttonWrapper} ${
      fullHeightHover ? classes.fullHeightHoverWrapper : ''
    }`;

    return (
      <div
        onBlur={this.reset}
        className={`${classes.root} ${
          this.props.className ? this.props.className : ''
        }`}
        onMouseOver={this.stop}
        onMouseOut={this.reset}
        role={'button'}
        tabIndex={-10}
      >
        {Array.isArray(this.props.children) ? (
          this.props.children.map((child, index) => {
            return (
              <CarouselItem
                key={`carousel-item${index}`}
                display={index === this.state.displayed}
                active={index === this.state.active}
                child={child}
                animation={animation}
                timeout={timeout}
                next={this.next}
                prev={this.prev}
              />
            );
          })
        ) : (
          <CarouselItem
            key={`carousel-item0`}
            display={true}
            active={true}
            child={this.props.children}
            animation={animation}
            timeout={timeout}
          />
        )}

        <div className={`${buttonWrapperCssClassValue} ${classes.next}`}>
          <IconButton
            className={`${buttonCssClassValue} ${classes.next}`}
            onClick={this.next}
            aria-label="Next"
            disableRipple
            style={{ right: this.props.buttonIdent, top: this.props.topIndent }}
          >
            <ArrowForward />
          </IconButton>
        </div>

        <div className={`${buttonWrapperCssClassValue} ${classes.prev}`}>
          <IconButton
            className={`${buttonCssClassValue}  ${classes.prev}`}
            onClick={this.prev}
            aria-label="Previous"
            disableRipple
            style={{ left: this.props.buttonIdent, top: this.props.topIndent }}
          >
            <ArrowBack />
          </IconButton>
        </div>

        {indicators ? (
          <Indicators
            classes={classes}
            length={this.props.children.length}
            active={this.state.active}
            press={this.pressIndicator}
          />
        ) : null}
      </div>
    );
  }
}

function CarouselItem(props) {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => props.next(),
    onSwipedRight: () => props.prev(),
  });

  return props.display ? (
    <div {...swipeHandlers} className="CarouselItem">
      {props.animation === 'slide' ? (
        <Slide direction="left" in={props.active} timeout={props.timeout}>
          <div>{props.child}</div>
        </Slide>
      ) : (
        <Fade in={props.active} timeout={props.timeout}>
          <div>{props.child}</div>
        </Fade>
      )}
    </div>
  ) : null;
}

function Indicators(props) {
  const classes = props.classes;

  let indicators = [];
  for (let i = 0; i < props.length; i++) {
    const className =
      i === props.active
        ? `${classes.indicator} ${classes.active}`
        : `${classes.indicator}`;
    const item = (
      <FiberManualRecordIcon
        key={i}
        size="small"
        className={className}
        onClick={() => {
          props.press(i);
        }}
      />
    );

    indicators.push(item);
  }

  return <div className={`${classes.indicators}`}>{indicators}</div>;
}

export default withStyles(styles)(Carousel);
