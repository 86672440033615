
  
  const SERVICESNEW_INFO = [
    {
      "title": "Software engineering",
      "text": "Tap into our full-stack, full-lifecycle software engineering and quality assurance services along with our solid experience in optimizing typical workflows and business processes. We've been on the forefront of IT innovations for over two decades already and know all nuts and bolts of custom software engineering for enterprises.",
      "img": "1.svg",
      "url": "/services/software-development"
    },
    {
      "title": "Google Cloud migration",
      "text": "Leverage the experience of our Google certified engineers to securely move your applications and data to Google Cloud Platform and take advantage of its industry-leading solution stack. We apply best industry practices with a clear and proven methodology to handle every stage of data and application migration to the cloud. We also know how to fit legacy and cloud-native apps together.",
      "img": "2.svg",
      "url": "/services/migration-to-google-cloud"
    },
    {
      "title": "CDAP support",
      "text": "Take advantage of a powerful open-source framework for building big data and analytics data pipelines. Purpose-built for quick and easy data processing, it enables data engineers to quickly create complex analytics solutions based on all popular Hadoop distributions, such as Hortonworks Data Platform, MapR Converged Data Platform, or Cloudera Enterprise Data Hub. As a company heavily involved in the development of CDAP, CyberVision has the most extensive knowledge of its capabilities and business applications.",
      "img": "3.svg",
      "url": "/services/cdap-support"
    },
    {
      "title": "Big data analytics, ML & AI",
      "text": "Convert your diverse business data into actionable insights and automate repetitive tasks with powerful modern tools for Big Data processing, machine learning, and artificial intelligence. Crunching complex data is one of our key specialties, which we apply for enterprise projects as well as for development of cutting-edge Big Data solutions for data scientists and analysts.",
      "img": "4.svg",
      "url": "/expertise/big-data-analytics"
    },
    {
      "title": "Google Cloud Data Fusion",
      "text": "Realize the full potential of your data with Google’s Cloud Data Fusion, a powerful data engineering tool based on CDAP, and streamline your company’s digital transformation. With CDF, you can build ETL / ELT data pipelines at a fraction of the usual time by using graphical interface and convenient drag-n-drop functionality. Data engineering becomes totally repeatable and organized via templates and connectors, so you could do more in less time!",
      "img": "5.svg",
      "url": "/expertise/google-cloud-platform/google-cloud-data-fusion"
    },
    {
      "title": "System support",
      "text": "Boost productivity and reliability with our single-source technical support options, which range from a single-application support to a one-stop shop package for all your IT needs. Choose a technology partner with an in-depth understanding of modern system support operations, transparent management and communication, and hands-on experience supporting complex, mission-critical business applications.",
      "img": "6.svg",
      "url": "/services/technical-support"
    },
    {
      "title": "DevOps services",
      "text": "Reduce operational complexities, cost, risk, and time to market for your applications by assigning your infrastructure chores to our experienced DevOps team. We can handle on-premises, cloud, and multi-cloud infrastructure for our clients, and work with all popular cloud providers. Using modern DevOps automation tools, we can help you build CI / CD pipelines that radically streamline your operations and ensure iron-clad reliability.",
      "img": "7.svg",
      "url": "/expertise/devops"
    },
    {
      "title": "IoT development",
      "text": "Capitalize on the exploding IoT revolution and unlock new sources of revenue via modern smart technologies, which we’ve been working with for years and delivered dozens of successful projects. CyberVision is the founding company of the Kaa IoT Platform, which has been used extensively for open-source projects as well as enterprise solutions. Using our in-depth cloud and analytics expertise, we can deliver the most ambitious end-to-end IoT projects with a reliable business outcome.",
      "img": "8.svg",
      "url": "/expertise/iot-development"
    },
  ]
  
  export default SERVICESNEW_INFO;
  