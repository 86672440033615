import React, { useCallback, useEffect, useRef, useState } from 'react';
import Section from '../Section';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './ServicesNew.module.scss';
import Typography from '@material-ui/core/Typography';
import GatsbyLink from 'gatsby-link';
import SERVICESNEW_INFO from '../../constants/servicesnew-info';


const ServicesNew = () => {
  const { s } = useStaticQuery(graphql`
    query {
      s: allFile(filter: { sourceInstanceName: { eq: "servicesImages" } }) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
    }
  `);

  const [serviceTitle, SetServiceTitle] = useState(SERVICESNEW_INFO[0].title);
  const [serviceText, SetServiceText] = useState(SERVICESNEW_INFO[0].text);

  function getImgPath(imgTitle) {
    return s.nodes.find(obj => obj.relativePath === imgTitle).publicURL;
  }

  return (
    <Section height={'auto'} backgroundColor="#F7F8FB">

      <div className={styles.services}>
        <div className={styles['services__left-block']}>

          <div id={styles['hexagons']}>
            <ul id={styles['categories']} className={styles['clr']}>
              
              {SERVICESNEW_INFO.map((item, i) => {
                  return (
                    <li
                    onMouseEnter={() => {
                      return (
                        SetServiceTitle(SERVICESNEW_INFO[i].title),
                        SetServiceText(SERVICESNEW_INFO[i].text)
                      )
                    }}
                    onMouseLeave={() => {
                      return (
                        SetServiceTitle(SERVICESNEW_INFO[0].title),
                        SetServiceText(SERVICESNEW_INFO[0].text)
                      )
                    }}
                    >
                      <div className={styles['flip-container']}>
                        <div className={styles['flipper']}>
                          <div className={styles['front']}>
                            <div className={styles['flip-content']}>
                              <GatsbyLink to={SERVICESNEW_INFO[i].url}>
                                <img src={getImgPath(SERVICESNEW_INFO[i].img)}/>
                              </GatsbyLink>
                            </div>
                          </div>
                        </div>
                      </div>   
                    </li>                   
                  )
              })}

            </ul>	
          </div>
        </div>

        <div className={styles['services__right-block']}>
          <Typography variant="h2" color="secondary">
            Services
          </Typography>
          <Typography variant="h3">
            {serviceTitle}
          </Typography>
          <Typography>
            {serviceText}
          </Typography>
          
        </div>

      </div>
    </Section>
    );


  
};

export default ServicesNew;
